import { OrganizationGroupId } from './organization-group';
import { TagId } from './tag';

export type ScenarioId = number;

export interface Scenario {
  readonly id: ScenarioId;

  name: string;

  status: ScenarioStatus;

  isRestricted: boolean;

  description: string | null;

  creatorId: string | null;

  creatorDisplayName: string | null;

  accessLevel: ScenarioAccessLevel;

  scenarioType: ScenarioType;

  createdAt: string;

  updatedAt: string;

  queuePriority: ScenarioQueuePriority;

  queueStatus: null | {
    id: number;
    status: ScenarioQueueStatus;
    statusUpdated: string;
    submitted: string;
    scheduled: string | null;
    submitterDisplayName: string;
  };

  tprQueueStatus: null | {
    status: string;
    periodStartAt: string;
    triggeredAt: string;
    startedAt: string | null;
    endedAt: string | null;
  };

  /**
   * Whether the scenario is a favorite of the current user.
   */
  isFavorite: boolean;

  /**
   * Gets the scenario tags.
   */
  tags: string[];
}

/**
 * Determines if the specified scenario is the base scenario.
 */
export function isBaseScenario(scenario: Scenario): boolean {
  return scenario.name.toLowerCase().trim() === 'base';
}

export interface CreateScenarioPayload {
  name: string;
  sourceScenarioId: number;
  scenarioType: ScenarioType;
  description?: string;
  queuePriority: ScenarioQueuePriority;
  includeOverrides: boolean;
  includeAdjustments: boolean;
  copyPermissions: boolean;
}

export interface PublishScenarioPayload {
  startDate: Date | string | null;
  endDate: Date | string | null;
}

export interface QueueScenarioPayload {
  scenarioId: ScenarioId;
  scheduledDate?: string | Date | null;
}

export enum ScenarioAccessLevel {
  None = 0,
  Read = 1,
  Write = 2,
  Execute = 4,
  Manage = 5,
}

export enum ScenarioQueuePriority {
  High = 1,
  Medium = 2,
  Low = 3,
}

export interface ScenarioPermission {
  groupId: OrganizationGroupId;
  accessLevel: number;
}

export type ScenarioQueueItemId = number;

export interface ScenarioQueueItem {
  readonly id: ScenarioQueueItemId;
  readonly scenarioId: number;
  readonly scenario: Pick<Scenario, 'id' | 'name'>;
  readonly eoStatus: string;
  readonly status: ScenarioQueueStatus;
  readonly submitterId: string | null;
  readonly cancellerId: string | null;
  readonly submitter?: {
    id: string;
    userName: string;
  };
  readonly canceller?: {
    id: string;
    userName: string;
  };
  readonly scheduledDate?: string;
  readonly submitted: string;
  readonly statusUpdated: string;
}

export interface ScenarioQueueProgress {
  readonly action: string;
  readonly message: string;
  readonly executionTime: number;
  readonly startTime: string;
  readonly endTime: string;
  readonly total: number;
  readonly taskState: number;
  readonly percentageCompleted: number;
  readonly expectedExecutionTime: number;
}

export enum ScenarioQueueStatus {
  Ready = 'Ready',
  Submitted = 'Submitted',
  Preparing = 'Preparing',
  Prepared = 'Prepared',
  PreparationFailed = 'Preparation Failed',
  Running = 'Running',
  Terminated = 'Terminated',
  Completed = 'Completed',
  Failed = 'Failed',
  Triggered = 'Triggered',
  Cancel = 'Cancel',
  Cancelled = 'Cancelled',
  Marshalling = 'Marshalling',
  Scheduled = 'Scheduled',
}

export type ScenarioQueueStatusGroupKey =
  | 'Idle'
  | 'Submitted'
  | 'Scheduled'
  | 'Busy'
  | 'Failed'
  | 'Cancelled'
  | 'Completed';

export const GROUPED_SCENARIO_QUEUE_STATUSES: Record<
  ScenarioQueueStatusGroupKey,
  ScenarioQueueStatus[]
> = {
  Idle: [
    ScenarioQueueStatus.Ready,
    ScenarioQueueStatus.Completed,
    ScenarioQueueStatus.Failed,
    ScenarioQueueStatus.Terminated,
    ScenarioQueueStatus.PreparationFailed,
    ScenarioQueueStatus.Cancelled,
  ],
  Scheduled: [ScenarioQueueStatus.Scheduled],
  Submitted: [ScenarioQueueStatus.Submitted],
  Busy: [
    ScenarioQueueStatus.Preparing,
    ScenarioQueueStatus.Prepared,
    ScenarioQueueStatus.Running,
    ScenarioQueueStatus.Triggered,
    ScenarioQueueStatus.Marshalling,
    ScenarioQueueStatus.Cancel,
  ],
  Cancelled: [
    ScenarioQueueStatus.Cancel,
    ScenarioQueueStatus.Terminated,
    ScenarioQueueStatus.Cancelled,
  ],
  Failed: [ScenarioQueueStatus.Failed, ScenarioQueueStatus.PreparationFailed],
  Completed: [ScenarioQueueStatus.Completed],
};

export enum ScenarioStatus {
  Active = 'Active',
  Creating = 'Creating',
  MarkedForDeletion = 'Marked For Deletion',
  TimePeriodRolloverQueued = ' Time Period Roll over Queued',
}

export enum ScenarioType {
  Dynamic = 'Dynamic',
  Static = 'Static',
}

export interface UpdateScenarioPayload {
  description: string | null;
  scenarioType: ScenarioType;
  queuePriority: ScenarioQueuePriority;
  isRestricted: boolean;
  tagIds: TagId[];
}

export interface SetScenarioPermissionsPayload {
  permissions: ScenarioPermission[];
}

export interface QueueScenariosPayload {
  scenarios: QueueScenarioPayload[];
}

export interface FavouriteScenariosPayload {
  scenarios: FavouriteScenario[];
  isFavorite: boolean;
}

export interface FavouriteScenario {
  scenarioId: ScenarioId;
  workspaceId: number;
}

export interface DeleteScenariosPayload {
  scenarios: DeleteScenario[];
}

export interface DeleteScenario {
  scenarioId: ScenarioId;
}

export interface UnDeleteScenariosPayload {
  scenarios: UnDeleteScenario[];
}

export interface UnDeleteScenario {
  scenarioId: ScenarioId;
}
