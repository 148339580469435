import { ScenarioId } from './scenario';

export type PipelineDefinitionId = number;

/**
 * Represents a pipeline definition.
 */
export interface PipelineDefinitionPreview {
  /**
   * The pipeline ID.
   */
  readonly id: PipelineDefinitionId;

  /**
   * The pipeline name.
   */
  name: string;

  /**
   * The pipeline description.
   */
  description: string;

  /**
   * Whether or not the pipeline requires a scenario when submitting a new job.
   */
  isScenarioRequired: boolean;

  /**
   * The UTC date and time the pipeline was created.
   */
  created: string;

  /**
   * The UTC date and time the pipeline was updated.
   */
  updated: string;

  /**
   * The latest job submitted for the pipeline definition.
   */
  latestJob: null | PipelineJobPreview;
}

export interface PipelineDefinition extends PipelineDefinitionPreview {
  /**
   * The pipeline tasks.
   */
  tasks: PipelineDefinitionTask[];

  /**
   * The pipeline parameters.
   */
  parameters: PipelineDefinitionParameter[];
}

export type PipelineCondition = 'Success' | 'Failure' | 'Cancelled' | 'Always';

export type PipelineDefinitionParameterId = number;

export interface PipelineDefinitionParameter {
  readonly id: PipelineDefinitionParameterId;

  key: string;

  defaultValue: string | null;

  inputType: string | null;
}

export enum PipelineDefinitionParameterInputType {
  Text = 'text',
  Number = 'number',
  Date = 'date',
  DateTime = 'datetime-local',
  Checkbox = 'checkbox',
}

export type PipelineDefinitionTaskId = number;

export interface PipelineDefinitionTask {
  /**
   * The task ID.
   */
  readonly id: PipelineDefinitionTaskId;

  /**
   * The task key.
   */
  taskKey: string;

  /**
   * The key.
   */
  key: string;

  /**
   * Whether the task is enabled.
   */
  isEnabled: boolean;

  /**
   * The status the task will report as when disabled.
   */
  disabledStatus: PipelineDisabledStatus | null;

  /**
   * The name.
   */
  name: string;

  /**
   * The condition that task will run under.
   */
  condition: PipelineCondition;

  /**
   * An optional key that references another task that will confine the condition to a particular task.
   */
  conditionTargetKey: string | null;
}

/**
 * Represents a pipeline task.
 */
export interface PipelineTask {
  /**
   * The pipeline task key used to identify the task.
   */
  readonly key: string;

  /**
   * The task name.
   */
  readonly name: string;

  /**
   * The task description.
   */
  readonly description: string;

  /**
   * The task inputs.
   */
  readonly inputs: PipelineTaskInput[];

  /**
   * The task outputs.
   */
  readonly outputs: PipelineTaskOutput[];
}

export type PipelineTaskInput = {
  /**
   * The input key.
   */
  readonly key: string;

  /**
   * The input label.
   */
  readonly label: string;

  /**
   * The input help text.
   */
  readonly helpText: string;

  /**
   * Whether the input is required.
   */
  readonly isRequired: boolean;

  /**
   * The type of the input.
   */
  readonly type?: string;

  /**
   * Values for select type. type=list
   */
  readonly values?: Record<keyof string, string>[];

  /**
   * List of modules for Maestro pipeline task.
   */
  readonly modules?: Modules[] | undefined;
};

type Modules = {
  /**
   * The module Id.
   */
  id: string;

  /**
   * The module description.
   */
  description?: string;

  /**
   * The module name.
   */
  name: string;

  /**
   * The module parameter list.
   */
  parameterList?: [{ key: string; description: string; defaultValue: string }] | undefined;
};

export type PipelineTaskOutput = {
  /**
   * The output key.
   */
  readonly key: string;

  /**
   * The output help text.
   */
  readonly helpText: string;
};

export type PipelineJobId = number;

export interface PipelineJobPreview {
  /**
   * The pipeline job ID.
   */
  readonly id: PipelineJobId;

  /**
   * The pipeline definition ID.
   */
  readonly pipelineDefinitionId: PipelineDefinitionId;

  /**
   * The pipeline job status.
   */
  status: PipelineJobStatus;

  /**
   * The pipeline job number.
   */
  jobNumber: number;

  /**
   * The pipeline job error message.
   */
  message: string | null;

  /**
   * The UTC date and time the job was created.
   */
  created: string;

  /**
   * The ID of the user who submitted the job.
   */
  submitterId: string;

  /**
   * The display name of the user who submitted the job.
   */
  submitterDisplayName: string;

  /**
   * The scenario the job was submitted against.
   */
  scenario: null | {
    id: ScenarioId;
    name: string;
  };

  /**
   * The UTC date and time the job was started.
   */
  startTime: string;

  /**
   * The UTC date and time the job ended.
   */
  endTime: string;

  /**
   * The job duration.
   */
  duration: number | null;

  /**
   * The job parameters.
   */
  parameters: Record<string, string>;
}

export interface PipelineJob extends PipelineJobPreview {
  /**
   * The job steps.
   */
  steps: PipelineJobStep[];
}

export type PipelineJobStepId = number;

export interface PipelineJobStep {
  readonly id: PipelineJobStepId;

  taskKey: string;

  name: string;

  status: PipelineJobStatus | null;

  message: string;

  isEnabled: boolean;

  disabledStatus: PipelineDisabledStatus | null;

  condition: PipelineCondition;

  startTime: string | null;

  endTime: string | null;

  duration: number | null;
}

export type PipelineJobLogId = string;

export interface PipelineJobLog {
  readonly id: PipelineJobLogId;

  readonly pipelineJobStepId: PipelineJobStepId | null;

  readonly message: string;

  readonly level: PipelineJobLogLevel;

  readonly timestamp: string;
}

export enum PipelineJobLogLevel {
  Debug = 1,

  Information = 2,

  Warning = 3,

  Error = 4,
}

export enum PipelineJobStatus {
  Scheduled = 'Scheduled',

  Queued = 'Queued',

  Failure = 'Failure',

  Success = 'Success',

  Running = 'Running',

  Cancelled = 'Cancelled',

  Skipped = 'Skipped',
}

export enum PipelineDisabledStatus {
  Success = 'Success',

  Failure = 'Failure',
}

export type PipelineScheduleId = number;

export interface PipelineSchedule {
  readonly id: PipelineScheduleId;

  /**
   * The schedule's cron expression.
   */
  cron: string;

  /**
   * Whether the schedule is enabled.
   */
  isEnabled: boolean;

  /**
   * The scenario the schedule was created for.
   */
  scenario: null | {
    id: ScenarioId;
    name: string;
  };

  /**
   * The schedule parameters.
   */
  parameters: Record<string, string>;
}
