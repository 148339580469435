import { HubConnection, HubConnectionBuilder } from '@microsoft/signalr';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';
import { stringify } from 'qs';

import { authClient } from 'auth';
import { ValidationException } from 'interfaces';

export class ApiClient {
  readonly #axios: AxiosInstance;

  constructor(baseUrl = `${process.env.REACT_APP_DECISIO_PATH}/api`) {
    this.#axios = axios.create({
      baseURL: baseUrl,
      paramsSerializer: (params) => {
        return stringify(params);
      },
    });

    this.#axios.interceptors.request.use(async (config) => {
      const accessToken = await authClient.getAccessToken();
      if (accessToken !== null) {
        config.headers = {
          ...config.headers,
          authorization: `Bearer ${accessToken}`,
        };
      }

      return config;
    });

    this.#axios.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 422) {
        return Promise.reject(new ValidationException(error.response.data));
      }

      return Promise.reject(error);
    });
  }

  /**
   * Performs a DELETE request to the specified URL.
   * @param url The request URL.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'DELETE', config).then((res) => res.data);
  }

  /**
   * Performs a GET request to the specified URL.
   * @param url The request URL.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'GET', config).then((res) => res.data);
  }

  /**
   * Performs a POST request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async post<T, D = any>(url: string, data: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'POST', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a PUT request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async put<T, D = any>(url: string, data: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'PUT', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a PATCH request to the specified URL.
   * @param url The request URL.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async patch<T, D = any>(url: string, data?: D, config?: AxiosRequestConfig): Promise<T> {
    return this.request<T>(url, 'PATCH', { ...config, data }).then((res) => res.data);
  }

  /**
   * Performs a request to the specified URL.
   * @param url The request URL.
   * @param method The request method.
   * @param data The request data.
   * @param config Additional axios request options.
   * @returns A `Promise` containing the response data.
   */
  async request<T>(
    url: string,
    method: Method,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    return this.#axios.request<T>({ url, method, ...config });
  }

  createHubConnection(url: string): HubConnection {
    return new HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_DECISIO_PATH}/hubs/${url}`, {
        withCredentials: false,
        accessTokenFactory: async () => (await authClient.getAccessToken()) ?? '',
      })
      .build();
  }
}

export function toFormData<T extends Record<string, any>>(
  data: T,
  sendArrayAsJSON = false,
  formData = new FormData(),
  parentKey = ''
): FormData {
  return Object.keys(data).reduce((acc, key) => {
    const value = data[key];
    const formKey = parentKey ? `${parentKey}.${key}` : key;

    console.log({ data });

    if (!value) {
      return acc;
    }

    if (Array.isArray(value)) {
      if (sendArrayAsJSON) {
        acc.append(key, `{${JSON.stringify(key)}:${JSON.stringify(value)}}`);
      } else {
        value.forEach((v, index) => acc.append(`${formKey}[${index}]`, v));
      }
    } else if (typeof value === 'object' && !(value instanceof File)) {
      toFormData(value, false, acc, formKey);
    } else {
      acc.append(formKey, value);
    }

    return acc;
  }, formData);
}

export const api = new ApiClient();
