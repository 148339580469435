import { OverrideType } from 'interfaces';

export enum EditorViewPanelType {
  Table,
  Graphs,
  Compare,
  ShowExceptions,
  Adjustments,
  KPIs,
}

export enum DataViewMode {
  DefaultMode = 'defaultMode',
  ExceptionMode = 'exceptionMode',
  ExceptionSummaryMode = 'exceptionSummaryMode',
}

export enum DataViewKpiAggregationType {
  Sum = 'Sum',
  Average = 'Average',
  Min = 'Min',
  Max = 'Max',
  Count = 'Count',
}

export enum ExceptionModeColumnNames {
  NoOfExceptions = 'NoOfExceptions',
  HasExceptions = 'HasExceptions',
}

export enum EditorViewSidePanelType {
  Adjustments = 'Adjustments',
  Overrides = 'Overrides',
  Help = 'Help',
}

export enum ContextMenuItemLabel {
  OpenDataViewInNewTap = 'open_dataview_in_new_tab',
  OpenDataView = 'open_dataview',
  RemoveRow = 'remove_row',
  Duplicate = 'duplicate',
}

export enum FilterExpressionOperation {
  GreaterThan = 'greaterthan',
  LessThan = 'lessthan',
  GreaterThanAndEqualTo = 'greaterthanorequal',
  LessThanAndEqualTo = 'lessthanorequal',
  Equals = 'equal',
  NotEqual = 'notequal',
  StartsWith = 'startswith',
  EndsWith = 'endswith',
  Contains = 'contains',
}

export enum FilterExpressionLabel {
  GreaterThan = 'Greater than',
  LessThan = 'Less than',
  GreaterThanAndEqualTo = 'Greater than or equal to',
  LessThanAndEqualTo = 'Less than or equal to',
  Equals = 'Equal',
  NotEqual = 'Not equal',
  StartsWith = 'Starts with',
  EndsWith = 'Ends with',
  Contains = 'Contains',
}

export enum LogicalExpressionCondition {
  And = 'and',
  Or = 'or',
}

export type DatasetRowId = number | string;

export type DatasetChange = {
  /**
   * The change id.
   */
  id?: string;

  /**
   * The index of the row being changed.
   */
  rowId: DatasetRowId;

  /**
   * The type of change.
   */
  type: OverrideType;

  /**
   * The changes.
   */
  values: DatasetChangeValue[];

  /**
   * If the change is an insert, this indicates the id of the dataset value to insert the new value before.
   */
  insertBeforeId?: number | string;
};

export type DatasetChangeValue = {
  /**
   * The name of the property being changed.
   */
  property: string;

  /**
   * The data view field ID.
   */
  dataViewFieldId: number;

  /**
   * The new value.
   */
  value: string;

  /**
   * The previous property value, if any.
   */
  previousValue: string | null;
};
