import { isPlainObject } from 'lodash';

import { nameof } from 'utils/nameof';

/**
 * Defines the types of messages available.
 */
export enum DecisioWindowMessageType {
  TaskOpen = 1,
}

/**
 * Represents a decisio window message.
 */
export class DecisioWindowMessage {
  /**
   * The application message signature value for messages sent and received by Decisio.
   */
  static readonly #decisioSignature: string = 'decisio';

  static readonly #signaturePropName = nameof<DecisioWindowMessage>('signature');
  static readonly #typePropName = nameof<DecisioWindowMessage>('type');
  static readonly #payloadPropName = nameof<DecisioWindowMessage>('payload');

  /**
   * The application signature holding the originator of the message.
   */
  readonly signature: string = DecisioWindowMessage.#decisioSignature;

  /**
   * Creates a new instance.
   * @param type The message type.
   * @param payload The message payload.
   */
  constructor(public readonly type: DecisioWindowMessageType, public readonly payload?: any) {}

  /**
   * Attempts to convert a raw message object into a typed `DecisioWindowMessage` instance.
   * @param obj The object to try and deserialize into an instance.
   * @returns A `DecisioWindowMessage` instance if valid; `null` otherwise.
   */
  static tryDeserialize(obj: any): DecisioWindowMessage | null {
    let deserializedMessage = null;

    if (
      isPlainObject(obj) &&
      DecisioWindowMessage.#signaturePropName in obj &&
      obj[DecisioWindowMessage.#signaturePropName] === DecisioWindowMessage.#decisioSignature &&
      DecisioWindowMessage.#typePropName in obj &&
      Object.values(DecisioWindowMessageType).includes(obj[DecisioWindowMessage.#typePropName])
    ) {
      deserializedMessage = new DecisioWindowMessage(
        obj[DecisioWindowMessage.#typePropName],
        obj[DecisioWindowMessage.#payloadPropName]
      );
    }

    return deserializedMessage;
  }
}
