import {
  ChangeEvent,
  Children,
  FocusEvent,
  MouseEvent,
  ReactChild,
  ReactElement,
  ReactNode,
  forwardRef,
  isValidElement,
} from 'react';

import { ControlInput, ControlInputProps, ControlInputState } from 'components/control-input';
import { Menu, MenuList, MenuOptionItem, MenuTrigger } from 'components/menu';
import { DropdownFade } from 'components/transitions';
import { useId } from 'hooks';

import { useSelect } from './hooks';
import { OptionProps } from './Option';

export type SelectProps<T = unknown> = {
  /**
   * The children to render.
   */
  children?: ReactNode;

  placeholder?: ReactChild;

  name?: string;

  id?: string;

  value?: any;

  multiple?: boolean;

  /**
   * The validation state.
   */
  state?: ControlInputState;

  inputProps?: ControlInputProps;

  onBlur?(event: FocusEvent): void;

  onChange?(event: ChangeEvent<{ name?: string; value: T; event: Event }>, child: ReactNode): void;
};

/**
 * Select component.
 */
export const Select = forwardRef<HTMLDivElement, SelectProps>(function Select(props, forwardedRef) {
  const {
    children,
    placeholder,
    multiple = false,
    name,
    state,
    value,
    id: idProp,
    inputProps = {},
    onBlur,
    onChange,
  } = props;

  const id = useId(idProp);

  const { isOpen, values, close, toggle, selectValue } = useSelect({
    value,
    multiple,
  });

  const handleBlur = (event: FocusEvent) => {
    if (!isOpen && onBlur) {
      Object.defineProperty(event, 'target', { writable: true, value: { value, name } });
      onBlur(event);
    }
  };

  const handleItemClick = (child: ReactElement) => (event: MouseEvent) => {
    if (!event.currentTarget.hasAttribute('tabindex')) {
      return;
    }

    const selectedValues = selectValue(child.props.value);
    const newValue = multiple ? selectedValues : selectedValues[0];
    if (newValue !== value) {
      if (onChange) {
        const nativeEvent = event.nativeEvent || event;
        const clonedEvent = new (nativeEvent.constructor as any)(nativeEvent.target, nativeEvent);

        Object.defineProperty(clonedEvent, 'target', {
          writable: true,
          value: { value: newValue, name },
        });

        onChange(clonedEvent, child);
      }
    }

    if (!multiple) {
      close();
    }
  };

  const childrenArray = Children.toArray(children);
  let viewValue: any[] = [];

  const items = childrenArray.map((child) => {
    if (!isValidElement(child)) {
      return null;
    }

    const childProps = child.props as OptionProps;
    let selected = false;

    if (multiple) {
      selected = values.some((v) => v === childProps.value);
      if (selected) {
        viewValue.push(childProps.children);
      }
    } else {
      selected = values[0] === childProps.value;
      if (selected) {
        viewValue = [childProps.children];
      }
    }

    return (
      <MenuOptionItem
        key={child.key}
        tabIndex={selected ? 0 : -1}
        checked={selected}
        value={childProps.value}
        role="option"
        onClick={handleItemClick(child)}
      >
        {childProps.children}
      </MenuOptionItem>
    );
  });

  return (
    <MenuTrigger open={isOpen} onChange={toggle}>
      <ControlInput
        ref={forwardedRef}
        dropdownIndicator
        placeholder={placeholder}
        tabIndex={0}
        state={state}
        id={id}
        onBlur={handleBlur}
        {...(inputProps as any)}
      >
        {viewValue.join(', ')}
      </ControlInput>

      <Menu placement="bottom-start" transition={DropdownFade} matchWidth>
        <MenuList>{items}</MenuList>
      </Menu>
    </MenuTrigger>
  );
});
