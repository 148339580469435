import { Profile, User } from 'oidc-client';
import { useCallback } from 'react';
import { atom, selector, useRecoilValue } from 'recoil';

import { LOGIN_CALLBACK_PATH, authClient } from './AuthClient';

type AuthState = {
  isAuthenticated: boolean;
  profile?: Profile;
  postLoginRedirectUrl?: string | null;
};

async function getInitialAuthState(): Promise<AuthState> {
  let isAuthenticated = await authClient.checkAuthenticated();
  let postLoginRedirectUrl: string | null = null;

  if (!isAuthenticated) {
    const { href, pathname, search } = window.location;
    if (href.includes(LOGIN_CALLBACK_PATH)) {
      const result = await authClient.completeSignIn(href);
      isAuthenticated = true;
      postLoginRedirectUrl = result?.state?.redirectUrl ?? '/';
    } else {
      isAuthenticated = await authClient.signIn({ redirectUrl: `${pathname}${search}` });
    }
  }

  if (isAuthenticated) {
    const user = await authClient.getUser();

    return {
      profile: user!.profile,
      isAuthenticated,
      postLoginRedirectUrl,
    };
  }

  return {
    isAuthenticated,
  };
}

export const authState = atom<AuthState>({
  key: 'auth',
  default: {
    isAuthenticated: false,
  },
  effects: [
    ({ setSelf }) => {
      let initialized = false;

      setSelf(
        getInitialAuthState().then((result) => {
          initialized = true;
          return result;
        })
      );

      function handleUserLoaded(user: User) {
        if (initialized) {
          setSelf((prevValue) => ({ ...prevValue, profile: user.profile } as AuthState));
        }
      }

      authClient.events.addUserLoaded(handleUserLoaded);

      return () => {
        authClient.events.removeUserLoaded(handleUserLoaded);
      };
    },
  ],
});

export const isAdminSelector = selector<boolean>({
  key: 'auth.isAdmin',
  get: ({ get }) => {
    const role = get(authState)?.profile?.role;
    return role === 'admin' || role?.includes('admin');
  },
});

export const displayNameSelector = selector<string>({
  key: 'auth.displayName',
  get: ({ get }) => get(authState)?.profile?.preferred_username ?? '',
});

export function useAuthState(): AuthState {
  return useRecoilValue(authState);
}

export function useIsAdmin(): boolean {
  return useRecoilValue(isAdminSelector);
}

export function useUserDisplayName() {
  return useRecoilValue(displayNameSelector);
}

export function useSignOut() {
  return useCallback(() => authClient.signOut(), []);
}
