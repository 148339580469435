import { Suspense, lazy, useCallback, useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  Outlet,
  Route,
  BrowserRouter as Router,
  Routes,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { RecoilRoot, useSetRecoilState } from 'recoil';

import { Auth } from 'auth';
import { LoadingSpinner } from 'components/loading-spinner';
import { Applications } from 'features/landing/Applications';
import { GlobalLayout } from 'global-layout';
import { DecisioWindowMessage, DecisioWindowMessageType } from 'interfaces/window-message';
import { Theme, useGlobalStyles } from 'stitches';

import { applicationPathState, organizationPathState, useCurrentApplication } from './AppState';
import { queryClient } from './queryClient';

const AccountRoot = lazy(() => import('features/account/AccountRoot'));
const AdminRoot = lazy(() => import('features/admin/AdminRoot'));
const ApplicationRoot = lazy(() => import('features/application/ApplicationRoot'));
const OrganizationRoot = lazy(() => import('features/organization/OrganizationRoot'));

/**
 * Main entry point component. Responsible for initializing application wide routing, context, state and
 * providers.
 */
export function App() {
  useGlobalStyles();

  return (
    <RecoilRoot>
      <QueryClientProvider client={queryClient}>
        <HelmetProvider>
          <Helmet titleTemplate="%s - Decisio" defaultTitle="Decisio" />

          <Suspense fallback={<LoadingSpinner />}>
            <Router>
              <Auth>
                <Theme />

                <GlobalLayout>
                  <Routes>
                    <Route element={<Root />}>
                      <Route index element={<Applications />} />
                      <Route path="account/*" element={<AccountRoot />} />
                      <Route path="admin/*" element={<AdminRoot />} />
                      <Route path="orgs/:orgPath/*" element={<OrganizationRoot />} />
                      <Route path=":orgPath/:applicationPath/*" element={<ApplicationRoot />} />
                    </Route>
                  </Routes>
                </GlobalLayout>
              </Auth>
            </Router>
          </Suspense>
        </HelmetProvider>

        <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
      </QueryClientProvider>
    </RecoilRoot>
  );
}

function Root() {
  const { orgPath = null, applicationPath = null } = useParams();
  const setApplicationPath = useSetRecoilState(applicationPathState);
  const setOrganizationPath = useSetRecoilState(organizationPathState);
  const currentApplication = useCurrentApplication(false);
  const navigate = useNavigate();

  const messageListener = useCallback(
    (event: MessageEvent) => {
      if (!event || event.origin !== window.location.origin) return;

      const decisioWindowMessage = DecisioWindowMessage.tryDeserialize(event.data);
      if (decisioWindowMessage) {
        if (
          decisioWindowMessage.type === DecisioWindowMessageType.TaskOpen &&
          typeof decisioWindowMessage.payload === 'string'
        ) {
          navigate(decisioWindowMessage.payload);
        } else {
          console.debug('Unhandled window message', decisioWindowMessage);
        }
      }
    },
    [navigate]
  );

  useEffect(() => {
    setOrganizationPath(orgPath);
    setApplicationPath(applicationPath);
  }, [orgPath, applicationPath, setOrganizationPath, setApplicationPath]);

  useEffect(() => {
    window.name = currentApplication?.id.toString() ?? '';
  }, [currentApplication]);

  useEffect(() => {
    window.addEventListener('message', messageListener);
    return () => window.removeEventListener('message', messageListener);
  }, [messageListener]);

  return <Outlet />;
}
